  <script>
import moment from 'moment';
export default {
  data() {
    return {
        filters : {
            telecallers_refs: "",
            daterange : null,
        },
    };
  },
  methods : {
    getFormatedDate(date){
        try {
          return moment(date).format('yyyy-MM-DD');
        }catch (err) {
          console.log(err);
          return null;
        }
    },
    getParams(params){ 
      let telecallers_ref_code =  this.filters.telecallers_refs && this.filters.telecallers_refs.length > 0 ? this.filters.telecallers_refs : null;
      if(telecallers_ref_code) {
        params.telecallers_ref_code = telecallers_ref_code;
      }
      console.log(params);
      let from_date = this.filters.daterange && this.filters.daterange.length > 0 ? this.getFormatedDate(this.filters.daterange[0]) : null;
      console.log(from_date);
      if(from_date){
        params.from_date = from_date;
        console.log(params);
      }
      let to_date = this.filters.daterange && this.filters.daterange.length > 0 && this.filters.daterange[1] ? this.getFormatedDate(this.filters.daterange[1]) : null;
      if(to_date){
        params.to_date = to_date;
      }
      this.params = {};
      this.params = params;
      return params;
    },
  },
  mounted() {
  },
  watch :{
    filters : {
      deep : true,
      handler() {
        let params = this.getParams({});
        this.$emit('filters',params);
      }
    },
  }
};
</script>
<template>
    <div class="block mb-4">
        <el-date-picker
            v-model="filters.daterange"
            format="MMM d"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date">
        </el-date-picker>
    </div>
</template> 