<template>
    <div class="px-3 pt-3">
        <div class="member-name">
            <!-- <span>Welcome,</span>
            <span style="font-weight: 700;">Vaishnavi P.</span> -->
        </div>
        <div>
            <div class="referral-text">Your referral code</div>
            <div>
                <input type="text" v-model="this.referral" disabled class="form-control">
            </div>
        </div>
        <hr>
        <div>
            <Filters @filters="setFilters" />
            <b-tabs content-class="mt-3">
                <b-tab title="Referral Summary" active>
                    <div>
                        <div style="overflow-x:auto;" v-if="referralSummary">
                            <table class="table table-lg">
                                <thead>
                                    <tr>
                                        <th scope="col">Status</th>
                                        <th scope="col">No.of Doctors</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Total Referrals</td>
                                        <td>{{ referralSummary.total_count }}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Prime</td>
                                        <td>{{ referralSummary.prime_count }}</td>
                                    </tr>
                                    <tr @click="toggleVerified" class="" style="background-color: #D1FAE5;">
                                        <td><i :class="{ 'fa fa-minus mr-2': showVerified, 'fa fa-plus mr-2': !showVerified }" aria-hidden="true"></i>Verified</td>
                                        <td>{{ referralSummary.verified_count }}</td>
                                    </tr>
                                    <template v-if="showVerified">
                                        <tr style="background-color: #ECFDF5;">
                                            <td>With Document</td>
                                            <td>{{ referralSummary.verified_with_document_count }}</td>
                                        </tr>
                                        <tr style="background-color: #ECFDF5;">
                                            <td>Without Document</td>
                                            <td>{{ referralSummary.verified_without_document_count }}</td>
                                        </tr>
                                    </template>
                                    <tr @click="togglePending" style="background-color: #E0F2FE;">
                                        <td><i :class="{ 'fa fa-minus mr-2': showPending, 'fa fa-plus mr-2': !showPending }" aria-hidden="true"></i>Pending</td>
                                        <td>{{ referralSummary.pending_document_count }}</td>
                                    </tr>
                                    <template v-if="showPending">
                                        <tr style="background-color: #F0F9FF;">
                                            <td>No Action Taken Documents</td>
                                            <td>{{ referralSummary.not_action_taken_count }}</td>
                                        </tr>
                                        <tr style="background-color: #F0F9FF;">
                                            <td>Awaiting Documents</td>
                                            <td>{{ referralSummary.awaiting_document_count }}</td>
                                        </tr>
                                        <tr style="background-color: #F0F9FF;">
                                            <td>Other Registrants </td>
                                            <td>{{ referralSummary.other_registrants }}</td>
                                        </tr>
                                    </template>
                                    <tr @click="toggleProcessing" style="background-color: #FEF9C3;">
                                        <td><i :class="{ 'fa fa-minus mr-2': showProcessing, 'fa fa-plus mr-2': !showProcessing }" aria-hidden="true"></i>Processing</td>
                                        <td>{{ referralSummary.processing_count }}</td>
                                    </tr>
                                    <template v-if="showProcessing">
                                        <tr style="background-color: #FEFCE8;">
                                            <td>Call To Verify (Not Yet Verified)</td>
                                            <td>{{ referralSummary.call_to_verify_not_verified_yet_count }}</td>
                                        </tr>
                                        <tr style="background-color: #FEFCE8;">
                                            <td>Under Process (Call To Verify)</td>
                                            <td>{{ referralSummary.under_processing_call_to_verify_count }}</td>
                                        </tr>
                                        <tr style="background-color: #FEFCE8;">
                                            <td>Under Process (Document To Verify)</td>
                                            <td>{{ referralSummary.under_processing_document_to_verify_count }}</td>
                                        </tr>
                                    </template>
                                    <tr style="background-color: #FEE2E2;">
                                        <td>Rejected</td>
                                        <td>{{ referralSummary.rejected_by_admin }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="overflow-x:auto;" v-else>
                            <table class="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Status</th>
                                    <th scope="col">No.of Doctors</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>Total Referrals</td>
                                    <td>0</td>
                                    </tr>
                                    <tr>
                                    <td>Total Prime</td>
                                    <td>0</td>
                                    </tr>
                                    <tr>
                                    <td>Verified</td>
                                    <td>0</td>
                                    </tr>
                                    <tr>
                                    <td>Pending</td>
                                    <td>0</td>
                                    </tr>
                                    <tr>
                                    <td>Processing</td>
                                    <td>0</td>
                                    </tr>
                                    <tr>
                                    <td>Rejected</td>
                                    <td>0</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Member Type">
                    <div>
                        <b-table
                            :items="memberType"
                            :fields="memberTypeFields"
                            striped
                            hover
                            :per-page="perPage"
                        >
                        </b-table>
                    </div>
                </b-tab>
                <b-tab title="City Coverage">
                    <div>
                        <b-table
                            :items="cityCoverage"
                            :fields="cityCoverageFields"
                            striped
                            hover
                            :per-page="perPage"
                        >
                        </b-table>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>
<style>
.table tbody tr {
    font-size: 16px;
    font-weight: 800;
}
.nav {
    flex-wrap: initial !important;
}
.nav-tabs {
    overflow-x: auto;
    padding-bottom: 10px;
}
.nav-tabs .nav-link {
    white-space: nowrap !important;
    font-size: 16px;
    font-weight: 600;
}
.member-name {
    font-size: 20px;
}
.referral-text {
    font-size: 14px;
    font-weight: 700;
}
.el-input__inner {
 border: 0px solid !important;
}
.el-date-range-picker {
    width: 290px !important;
    top: 260px !important;
}
.el-date-range-picker .el-picker-panel__body {
    display: grid;
    min-width: 580px;
}
.el-date-range-picker__content {
    padding: 0px 16px !important;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 220px !important;
    background: #DBEAFE;
}
.el-icon-date:before {
    color: #3B82F6;
    font-size: 20px;
}
.el-date-editor .el-range-input {
    width: 50% !important;
    background: #DBEAFE;
    color: #3B82F6 !important;
    font-size: 16px;
    font-weight: 700;
}
.el-date-editor .el-range-separator {
    width: 15% !important;
}
body {
    background: #fff;
}
.nav-tabs {
    border-bottom: 0px !important;
    /* width: max-content;
    overflow-x: auto; */
}
.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 0px solid transparent !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-bottom: 1px solid !important; 
    color: #0EA5E9;
    font-size: 16px;
    font-weight: 600;
}
</style>
<script>
/**
 * Dashboard component
 */
import appConfig from "@/app.config";
import Filters from './filters.vue';

export default {
    components: {
        Filters
    },
  data() {
    return {
        showVerified: false,
        showProcessing: false,
        showPending: false,
        fieldMrData: [],
        title: "Cases",
        items: [
            {
            text: "Medisage",
            },
            {
            text: "Cases",
            active: true,
            },
        ],
        referral: '',
        filters: {},
        reload: 0,
        params: {},
        loading: true,
        referralSummary: [],
        memberTypeFields: [
            { key: 'member_type', label: 'Type'}, 
            { key: 'total_count', label: 'No.of Doctors'}
        ],
        memberType: [
            { member_type: 'doctor', label: 'Doctor'}
        ],

        cityCoverageFields: [
            { key: 'city', label: 'City'}, 
            { key: 'total_count', label: 'Referrals'}
        ],
        cityCoverage: [],
    };
  },
  created() {
    this.isMobile();
  },
  methods: {
    toggleVerified() {
        if (!this.showVerified) {
            this.showVerified = true;
        } else {
            this.showVerified = false;
        }
    },
    togglePending() {
        if (!this.showPending) {
            this.showPending = true;
        } else {
            this.showPending = false;
        }
    },
    toggleProcessing() {
        if (!this.showProcessing) {
            this.showProcessing = true;
        } else {
            this.showProcessing = false;
        }
    },
    isMobile() {
        if (screen.width <= 760) {
            return true
        } else {
            return false
        }
    },
    setFilters(v) {
        this.filters = v;
    },
    async getTelecallerRefs() {
        try{
            let params = Object.keys(this.filters).length > 0 ? this.filters : {};
            const res = await this.$http.get(appConfig.api_base_url + "/referred-by-fieldmrs?telecaller_ref_codes[]=" + this.referral, {
                params : params
            });
            this.fieldMrData = res.data.summary;
            this.cityCoverage = this.fieldMrData.map(c => {
                return {
                    city : c.city,
                    total_count : c.total_count,
                }
            });
            this.memberType = [];
            this.fieldMrData.forEach(f => {
                let index = this.memberType.findIndex((m) => m.member_type == f.member_type);
                if(index == -1){
                    this.memberType.push({
                        total_count : f.total_count,
                        member_type : f.member_type,
                    });
                }else{
                    this.memberType[index].total_count += f.total_count;
                }
            });
            const compressed = this.fieldMrData.reduce((a, c) => {
                a = a.filter(f => f.referral_code != null && f.referral_code != '');
                const dateIndex = a.findIndex(
                    (o) =>
                        (o.referral_code).toUpperCase() === (c.referred_by).toUpperCase() &&
                        (c.referred_by != null || c.referred_by != "null" || c.referred_by != "")
                );
                if (dateIndex !== -1) {
                    a[dateIndex].total_count += parseInt(c.total_count);
                    a[dateIndex].prime_count += parseInt(c.prime_count);

                    a[dateIndex].verified_with_document_count += parseInt(c.verified_with_document_count);
                    a[dateIndex].verified_without_document_count += parseInt(c.verified_without_document_count);
                    
                    a[dateIndex].not_action_taken_count += parseInt(c.not_action_taken_count);
                    a[dateIndex].awaiting_document_count += parseInt(c.awaiting_document_count);
                    a[dateIndex].other_registrants += parseInt(c.other_registrants);

                    a[dateIndex].call_to_verify_not_verified_yet_count += parseInt(c.call_to_verify_not_verified_yet_count);
                    a[dateIndex].under_processing_call_to_verify_count += parseInt(c.under_processing_call_to_verify_count);
                    a[dateIndex].under_processing_document_to_verify_count += parseInt(c.under_processing_document_to_verify_count);
                    
                    a[dateIndex].rejected_by_admin += parseInt(c.rejected_by_admin);

                    a[dateIndex].verified_count = (a[dateIndex].verified_with_document_count + a[dateIndex].verified_without_document_count);
                    a[dateIndex].pending_document_count = (a[dateIndex].awaiting_document_count + a[dateIndex].not_action_taken_count + a[dateIndex].other_registrants);
                    a[dateIndex].processing_count = (a[dateIndex].call_to_verify_not_verified_yet_count + a[dateIndex].under_processing_call_to_verify_count + a[dateIndex].under_processing_document_to_verify_count);
                  } else {
                    a.push({
                    total_count: c.total_count,
                    member_type: c.member_type,
                    referral_code: c.referred_by,
                    prime_count: c.prime_count,
                    verified_count: (c.verified_with_document_count + c.verified_without_document_count),
                    verified_with_document_count: c.verified_with_document_count,
                    verified_without_document_count: c.verified_without_document_count,
                    
                    pending_document_count: (c.awaiting_document_count + c.not_action_taken_count + c.other_registrants),
                    awaiting_document_count: c.awaiting_document_count,
                    not_action_taken_count: c.not_action_taken_count,
                    other_registrants: c.other_registrants,

                    processing_count: (c.call_to_verify_not_verified_yet_count + c.under_processing_call_to_verify_count + c.under_processing_document_to_verify_count ),
                    call_to_verify_not_verified_yet_count: c.call_to_verify_not_verified_yet_count,
                    under_processing_call_to_verify_count: c.under_processing_call_to_verify_count,
                    under_processing_document_to_verify_count: c.under_processing_document_to_verify_count,
                    
                    rejected_by_admin: c.rejected_by_admin,
                    });
                }
                return a;
                }, []);
            this.referralSummary = compressed.length > 0 ? compressed[0] : null;
          }catch(err) {
            console.log(err);
          }  
    },
    handleRowClicked(item) {
      this.allOpenRows.map((ele) => {
        if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, "_showDetails", !ele._showDetails);
        }
      });
      this.allOpenRows = [];
      this.$set(item, "_showDetails", !item._showDetails);
      this.allOpenRows.push(item);
    },
  },
  mounted() {
    this.referral = this.$route.query.ref_code;
    this.getTelecallerRefs();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getTelecallerRefs();
      },
    },
  },
};
</script>